<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="500px" @close="close">
    <el-form ref="form" :model="form"  label-width="80px" @submit.native.prevent>
      <!-- 编码 -->
      <el-form-item label="国家名称">
        <el-input v-model.trim="form.name" autocomplete="off" placeholder="请输入" maxlength="20"
          show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    KorPic_country_update,
    KorPic_country_add
  } from '@/api/xgcApi'


  export default {
    name: 'country',
    components: {},
    data() {
      return {
        form: {},

        title: '',
        dialogFormVisible: false,
        isShow: true,
      }
    },
    methods: {
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.isShow = true
          this.title = '编辑'
          this.form = row
        }
        this.dialogFormVisible = true
      },
      close() {
        this.form.name = ''
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {

          if (this.form.name) {
            if (this.isShow == false) {
              const {
                msg
              } =  KorPic_country_add(this.form)
              this.$baseMessage(msg, 'success')
              this.form.name = ''
              this.dialogFormVisible = false
              this.$emit('fetch-data')
            } else {
              const {
                msg
              } =  KorPic_country_update(this.form)
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.form.name = ''
              this.dialogFormVisible = false
            }
          } else {
            return false
          }

      },
    },
  }
</script>
