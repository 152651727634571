<template>
  <!-- 帮助中心————组件 -->
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    top="5vh"
    width="800px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="类型">
        <el-radio-group v-model="radio">
          <el-radio :label="0">韩到中配置</el-radio>
          <el-radio :label="1">中到韩配置</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入标题"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item v-if="setList" label="排序" prop="sort">
        <el-input
          v-model.trim="form.sort"
          autocomplete="off"
          placeholder="请输入标题"
          maxlength="4"
          show-word-limit
          @input="handleInput"
        ></el-input>
      </el-form-item>

      <el-form-item label="详情" prop="content">
        <!-- <vab-markdown-editor
          ref="mde"
          v-model="value"
          @show-html="handleShowHtml"
        ></vab-markdown-editor> -->
        <div class="editor-wrapper">
          <!-- 改进quill的图片base64功能，改为上传后拼接url，上传组件隐藏 -->
          <!-- :on-success="handleQuillImgSuccess" -->
          <el-upload
            class="quill-upload"
            :http-request="upload"
            action="#"
            :show-file-list="false"
            with-credentials
            accept="image/gif, image/jpeg, image/jpg, image/bmp, image/png"
            style="display: none"
          >
            <i id="imgInput" class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
          <!--  -->
          <quill-editor
            ref="myQuillEditor"
            v-model="value"
            :options="editorOption"
            @change="onEditorChange($event)"
          />
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addList, newsDetail, newsUpdate } from '@/api/xgcApi'
  import VabMarkdownEditor from '@/plugins/vabMarkdownEditor'
  import exoprt_file from '@/utils/expor_file' // 导出excel文件
  import { getUploadUrl } from '@/config'
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ['clean'], // remove formatting button
    ['image'],
  ]
  export default {
    name: 'TableEdit',
    components: { VabMarkdownEditor },
    data() {
      return {
        editorOption: {
          placeholder: '请输入内容',
          modules: {
            toolbar: {
              container: toolbarOptions,
              handlers: {
                // 重写点击组件上的图片按钮要执行的代码
                image: function (value) {
                  document
                    .querySelector('.quill-upload .el-upload__input')
                    .click()
                },
              },
            },
          },
        },
        upUrl: '/index/index/file', //上传图片的接口
        editor: null,
        form: {
          name: '',
          content: '',
        },
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          sort: [{ required: true, trigger: 'blur', message: '请输入排序' }],
          content: [{ required: true, trigger: 'blur', message: '新闻详情' }],
        },
        title: '',
        radio: 1,
        dialogFormVisible: false,
        setList: false,
        value: '',
      }
    },
    created() {},
    methods: {
      /**
       *@name 富文本编辑器
       *@time 2024年4月23日15:01:58
       */
      // ---------------------------富文本编辑器开始--------------------------------------
      onEditorChange(e) {},
      upload(file) {
        // 图片上传
        file = file.file
        var data = {
          file: file,
        }
        var url = ''
        exoprt_file(this.upUrl, data).then((res) => {
          var pic = getUploadUrl + res
          let quill = this.$refs.myQuillEditor.quill
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片 res.url为服务器返回的图片地址
          quill.insertEmbed(length, 'image', pic)
          // 调整光标到最后
          quill.setSelection(length + 1)
          // 调用编辑器的 insertEmbed 方法，插入URL
        })
      },
      // -----------------------------------------------------------------------------------------
      handleInput() {
        // 移除非数字字符和前导零
        this.form.sort = this.form.sort.replace(/\D/g, '').replace(/^0+/, '')
      },
      async showEdit(row) {
        if (!row) {
          this.value = ''
          this.title = '添加'
          this.setList = false
        } else {
          this.title = '编辑'
          this.value = ''
          newsDetail({ id: row.id }).then((res) => {
            var data = res.data
            this.form = data
            this.radio = data.type
            this.value = data.content
            this.setList = true
            this.dialogFormVisible = true
          })

          // this.form = Object.assign({}, row)
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.form.type = this.radio
        this.form.content = this.value
        if (this.setList == false) {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const { msg } = await addList(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              return false
            }
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              const { msg } = await newsUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              return false
            }
          })
        }
      },
    },
  }
</script>
<style>
  .ql-container {
    height: 400px;
  }
</style>
