<template>
  <!-- 中到韩邮费模板————组件 -->
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px" @close="close">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 名称 -->
      <el-form-item label="运送名称" prop="name">
        <el-input v-model.trim="form.name" autocomplete="off" maxlength="15" show-word-limit></el-input>
      </el-form-item>
      <!-- 国家名称 -->
      <el-form-item label="运送国家" prop="type">
        <el-select v-model="form.type" placeholder="请选择" @change="handleChange">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- 运送方式 -->
      <el-form-item label="运送方式" prop="shipment_type">
        <el-select v-model="form.shipment_type" placeholder="请选择">
          <el-option v-for="item in statusList" :key="item.type" :label="item.text" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <!-- 首重 -->
      <el-form-item label="首重" prop="first_number">
        <el-input v-model.trim="form.first_number" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInput"></el-input>
      </el-form-item>
      <!-- 首重价格 -->
      <el-form-item label="首重价格" prop="first_amount">
        <el-input v-model.trim="form.first_amount" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInput1"></el-input>
      </el-form-item>

      <el-form-item label="续重" prop="repeat_number">
        <el-input v-model.trim="form.repeat_number" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInput2"></el-input>
      </el-form-item>

      <el-form-item label="续重价格" prop="repeat_amount">
        <el-input v-model.trim="form.repeat_amount" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInput4"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    addList,
    getKopPostageDetail,
    updateKopPostage,
  } from '@/api/korPostage'
  import {
    KorPic_country_list,
    KorPic_list
  } from '@/api/xgcApi'

  export default {
    // name: 'UserManagementEdit',

    data() {
      return {
        editor: null,
        form: {
          type: '',
          shipment_type : ''
        },
        //控制对话框的显示隐藏
        dialogFormVisible: false,
        //校验规则
        rules: {
          name: [{
            required: true,
            trigger: 'blur',
            message: '请输入名称',
          }, ],

          first_number: [{
            required: true,
            trigger: 'blur',
            message: '请输入首重',
          }, ],

          first_amount: [{
            required: true,
            trigger: 'blur',
            message: '请输入首重价格',
          }, ],

          repeat_number: [{
            required: true,
            trigger: 'blur',
            message: '请输入续重',
          }, ],

          repeat_amount: [{
            required: true,
            trigger: 'blur',
            message: '请输入续重价格',
          }, ],
          shipment_type: [{
            required: true,
            trigger: 'change',
            message: '请选择运送方式',
          }, ],
          type: [{
            required: true,
            trigger: 'change',
            message: '请选择运送国家',
          }, ],
        },
        title: '',
        //判断是添加还是编辑 添加是true 编辑是false
        isShow: false,
        options: [],

        //下拉列表的值
        statusList: [],
      }
    },

    created() {
      this.getCountryList()
    },
    methods: {
      // 获取国家列表
      getCountryList() {
        KorPic_country_list({
          limit: 200,
        }).then((res) => {
          this.options = res.data.list
        })
      },
      // 选择国家
      handleChange(value) {
        // 在这里可以根据value执行查询操作
        this.getPicList(value)
        this.form.shipment_type = ''
        this.statusList = []

      },
      // 获取运送方式列表
      getPicList(e) {
        KorPic_list({
          country_id: e
        }).then((res) => {
          this.statusList = res.data.list
        })
      },

      //首重的输入校验
      handleInput() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.first_number = this.form.first_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },

      //首重价格的输入校验
      handleInput1() {
        this.form.first_amount = this.form.first_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },

      //续重的输入校验
      handleInput2() {
        this.form.repeat_number = this.form.repeat_number
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },

      //续重价格的输入校验
      handleInput4() {
        this.form.repeat_amount = this.form.repeat_amount
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.title = '编辑'
          this.isShow = true
          //编辑时候传入ID查询详情用于在Form表单中回显
          const {
            data
          } = await getKopPostageDetail({
            id: row.id,
          })
          this.form = data
        }
        this.dialogFormVisible = true
      },

      close() {
        //清空Form表单中输入框的值
        this.$refs['form'].resetFields()
        //清空校验规则
        this.form = this.$options.data().form
        //关闭对话框
        this.dialogFormVisible = false
      },
      save() {
        //如果isShow的值是true调用添加方法 否则调用编辑方法
        if (this.isShow == false) {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              //调用添加方法
              const {
                msg
              } = await addList(this.form)
              this.$baseMessage(msg, 'success')
              //方法完成之后调用回调函数 刷新页面
              this.$emit('fetch-data')
              this.close()
            } else {
              return false
            }
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              //调用编辑方法
              const {
                msg
              } = await updateKopPostage(this.form)
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              //关闭对话框
              this.close()
            } else {
              return false
            }
          })
        }
      },
    },
  }
</script>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-dialog__body {
    height: 45vh;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
