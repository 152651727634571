<template>
  <div class="table-container">
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        align="center"
        width="150"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="文章标题"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="类型"
        prop=""
        width="340"
        sortable
        align="center"
      >
        <template #default="{ row }">
          <div v-if="row.type == 0">韩到中配置</div>
          <div v-if="row.type == 1">中到韩配置</div>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="时间"
        prop="add_time"
        width="340"
        sortable
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="推荐"
        width="250px"
        align="center"
      >
        <template #default="{ row }">
          <el-switch
            v-model="row.status"
            @change="switchChange($event, row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="250px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <table-edit ref="edit" @fetch-data="getnewlist"></table-edit>
  </div>
</template>

<script>
  import { getNewsList, deleteList, News_status } from '@/api/xgcApi'
  import TableEdit from '../components/TableEdit'
  export default {
    name: 'ComprehensiveTable',
    components: {
      TableEdit,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        height: '78vh',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 修改推荐
      switchChange(e, row) {
        let data = {
          id: row.id, //id
          status: row.status, //0正常 1推荐
        }
        console.log(data, '返回信息')
        News_status(data)
          .then((res) => {
            this.$message({
              type: 'success',
              message: '操作成功',
            })
            this.getnewlist()
          })
          .catch((err) => {
            this.$message({
              type: 'error',
              message: '操作失败',
            })
            this.getnewlist()
          })
      },
      // 获取数据
      getnewlist() {
        this.fetchData()
      },
      // 编辑列表数据，吊起子组件
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      //获取列表数据
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getNewsList(this.queryForm)
        this.list = data.list
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
