<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 国家名称 -->
      <el-form-item label="国家名称" prop="text">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 编码 -->
      <el-form-item label="名称" prop="text">
        <el-input
          v-model.trim="form.text"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 邮编 -->
      <!--     <el-form-item label="排序号" prop="type">
        <el-input
          v-model.trim="form.type"
          autocomplete="off"
          placeholder="请输入"
          maxlength="10"
          show-word-limit
          @input="handleInput"
        ></el-input>
      </el-form-item> -->
      <!-- 产品图片 -->
      <el-form-item label="图片" prop="pic">
        <uploadFile
          :limit="1"
          :img-list-string="form.pic"
          @fileChange="uploadChange($event, 'pic')"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { KorPic_update, KorPic_add, KorPic_country_list } from '@/api/xgcApi'
  import uploadFile from '@/components/uploadFile'

  export default {
    name: 'ManageStore',
    components: {
      uploadFile,
    },
    data() {
      return {
        form: {},
        rules: {
          text: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          // type: [{ required: true, trigger: 'blur', message: '请输入内容' }],
        },
        title: '',
        dialogFormVisible: false,
        isShow: true,
        options: [],
        value: '',
      }
    },
    created() {
      this.getCountryList()
    },
    methods: {
      // 获取国家列表
      getCountryList() {
        KorPic_country_list({ limit: 200 }).then((res) => {
          this.options = res.data.list
        })
      },
      // 上传图片
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      handleInput() {
        // Remove non-numeric characters and leading zeros
        this.form.sort = this.form.sort.replace(/\D/g, '').replace(/^0+/, '')
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = false
        } else {
          this.isShow = true
          this.title = '编辑'

          console.log(row)
          // const { data } = await KorPic_update(row.id)
          this.form = row
          // this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.form.text = ''
        // this.form.type = ''
        this.form.pic = ''
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == false) {
              this.form.country_id = this.value
              const { msg } = await KorPic_add(this.form)
              this.$baseMessage(msg, 'success')
              this.form.text = ''
              // this.form.type = ''
              this.form.pic = ''
              this.dialogFormVisible = false
              this.$emit('fetch-data')
            } else {
              const { msg } = await KorPic_update(this.form)
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.form.text = ''
              // this.form.type = ''
              this.form.pic = ''
              this.dialogFormVisible = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
