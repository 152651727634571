<template>
  <div class="table-container">
    <vab-query-form style="margin: 0">
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        label-width="100px"
        @submit.native.prevent
      >
        <el-form-item label="用户ID">
          <el-input
            v-model="queryForm.user_code"
            placeholder="请输入ID"
            style="width: 215px"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="支付账号">
          <el-input
            v-model="queryForm.pay_code"
            style="width: 215px"
            placeholder="请输入账号"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            v-model="queryForm.order_code"
            style="width: 250px"
            placeholder="请输入订单号"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="订单转运单号">
          <el-input
            v-model="queryForm.order_express_code"
            placeholder="请输入订单转运单号"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-form
      ref="form"
      :model="queryForm"
      :inline="true"
      label-width="100px"
      @submit.native.prevent
    >
      <el-form-item label="支付状态">
        <el-select
          v-model="value"
          clearable
          placeholder="请选择"
          @keyup.enter.native="handleQuery"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="支付类型">
        <el-select
          v-model="value1"
          clearable
          placeholder="请选择"
          @keyup.enter.native="handleQuery"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="支付时间">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="handleDateChange"
        ></el-date-picker>
      </el-form-item>

      <div style="float: right">
        <el-button
          icon="el-icon-search"
          type="primary"
          native-type="submit"
          @click="handleQuery"
        >
          查询
        </el-button>
        <el-button type="primary" @click="exportForm()">批量导出</el-button>
      </div>
    </el-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <!-- 编码 -->
      <el-table-column
        align="center"
        width="120"
        show-overflow-tooltip
        prop="user_code"
        label="用户id"
      ></el-table-column>
      <!-- 用户名称 -->
      <el-table-column
        align="center"
        width="150"
        show-overflow-tooltip
        prop="nickname"
        label="用户名称"
      ></el-table-column>
      <!-- 支付账号 -->
      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="pay_code"
        label="支付账号"
        width="250"
      ></el-table-column>

      <!-- 订单号 -->
      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="order_code"
        label="订单号"
        width="300"
      ></el-table-column>
      <!-- icb账号 -->
      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="icb_code"
        label="ICB账号"
        width="200"
      ></el-table-column>

      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="order_express_code"
        label="订单转运单号"
        width="200"
      ></el-table-column>

      <!--  <el-table-column
        show-overflow-tooltip
        prop="buy.name"
        label="物品名称"
      ></el-table-column> -->

      <el-table-column
        show-overflow-tooltip
        prop="price"
        label="价格"
      ></el-table-column>
      <!-- 退款金额 -->
      <el-table-column
        align="center"
        show-overflow-tooltip
        prop="refund_price"
        label="退款金额"
      >
        <template #default="{ row }">
          <span v-if="row.status == 2">{{ row.refund_price }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="status"
        label="支付状态"
        align="center"
      >
        <template #default="{ row }">
          <el-tag v-if="row.status == 0" size="mini" type="danger">
            未支付
          </el-tag>
          <el-tag v-if="row.status == 1" size="mini" type="success">
            已支付
          </el-tag>
          <el-tag v-if="row.status == 2" size="mini" type="danger">
            已退款
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="type"
        label="支付类型"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.type == 1">包裹订单</span>
          <span v-if="row.type == 2">补拍订单</span>
          <span v-if="row.type == 3">代买订单</span>
          <span v-if="row.type == 4">代发订单</span>
          <span v-if="row.type == 5">代买商品订单</span>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="支付时间"
        prop="pay_time"
        width="160"
        align="center"
      ></el-table-column>
      <el-table-column prop="remark" label="备注" width="300">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.remark"
            style="width: 100%"
            @change="handleEdit1(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 50, 100, 200, 300, 400, 500]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { payList, oexcelOutPay, payUpdate } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'
  export default {
    name: 'ManagePay',
    components: {},
    data() {
      return {
        height: '72vh',
        list: [],
        background: true,
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
        },
        value: '',
        value1: '',
        options: [
          {
            value: '0',
            label: '未支付',
          },
          {
            value: '1',
            label: '已支付',
          },
          {
            value: '2',
            label: '已退款',
          },
        ],
        options1: [
          {
            value: '1',
            label: '包裹订单',
          },
          {
            value: '2',
            label: '补拍订单',
          },
          {
            value: '3',
            label: '代买订单',
          },
          {
            value: '4',
            label: '代发订单',
          },
          {
            value: '5',
            label: '代买商品订单',
          },
        ],
        time: '',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    methods: {
      // 添加备注
      handleEdit1(index, row) {
        payUpdate(row).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
          this.fetchData()
        })
      },
      // 选择时间方法
      handleDateChange(value) {
        if (value != null) {
          this.queryForm.start_time = value[0]
          this.queryForm.end_time = value[1]
        } else {
          this.queryForm.start_time = ''
          this.queryForm.end_time = ''
        }
      },

      // 批量导出
      exportForm() {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定导出已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            const { data } = await oexcelOutPay({
              id: ids,
            })
            const fileUrl = getUploadUrl + data
            window.open(fileUrl)
            this.$message({
              type: 'success',
              message: '导出成功',
            })
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      },
      // 多选方法
      setSelectRows(val) {
        this.selectRows = val
      },

      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询方法
      handleQuery() {
        this.queryForm.page = 1
        this.queryForm.status = this.value
        this.queryForm.type = this.value1
        this.fetchData()
      },
      // 获取支付列表数据
      async fetchData() {
        this.listLoading = true
        const { data } = await payList(this.queryForm)
        this.list = data.list
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  :deep .el-form-item--small.el-form-item {
    margin-bottom: 10px !important;
  }
</style>
