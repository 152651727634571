<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form
      v-if="dialogFormVisible"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="所属用户" prop="uid">
        <el-select
          v-model="form.uid"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="`${item.nickname} （${item.code ? item.code : '-'}）`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 所属状态 -->
      <el-form-item label="所属状态" prop="status">
        <el-select v-model="value1" placeholder="请选择">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!--  -->
      <el-form-item v-if="showCangku" label="仓库所在区" prop="type">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号" prop="code">
        <el-input v-model.trim="form.code" autocomplete="off"></el-input>
      </el-form-item>
      <!-- 运单号 -->
      <el-form-item label="运单编号" prop="express_code">
        <el-input
          v-model.trim="form.express_code"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- 重量 -->
      <el-form-item label="订单重量" prop="weight">
        <el-input
          v-model.trim="form.weight"
          autocomplete="off"
          maxlength="8"
          show-word-limit
          @input="handleInputPrice"
        ></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="订单价格" prop="price">
        <el-input
          v-model.trim="form.price"
          autocomplete="off"
          maxlength="8"
          show-word-limit
          @input="handleInputPrice"
        ></el-input>
      </el-form-item>
      <!-- 产品图片 -->
      <el-form-item label="包裹图片" prop="pic">
        <uploadFile
          :limit="1"
          :img-list-string="form.pic"
          @fileChange="uploadChange($event, 'pic')"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { orderDetail, memberListAll, orderUpdate } from '@/api/xgcApi'
  import { getUploadUrl } from '@/config'
  import uploadFile from '@/components/uploadFile'
  import { goodsAdd } from '../../../api/xgcApi'

  export default {
    name: 'UserManagementEdit',
    components: {
      uploadFile,
    },
    data() {
      return {
        loading: false,
        options: [],
        value1: '',
        typeList: [
          { label: '韩区包裹', value: 1 },
          { label: '日区包裹', value: 2 },
        ],
        statusList: [
          {
            value: 0,
            label: '未入库',
          },
          {
            value: 1,
            label: '已入库',
          },
          {
            value: 2,
            label: '待审核',
          },
          {
            value: 3,
            label: '待打包',
          },
          {
            value: 4,
            label: '待发货',
          },
          {
            value: 5,
            label: '待支付',
          },
          {
            value: 6,
            label: '转运中',
          },
          {
            value: 7,
            label: '已签收',
          },
          {
            value: 10,
            label: '暂存',
          },
        ],

        showCangku: true,
        form: { uid: '' },
        dialogFormVisible: false,
        rules: {
          type: [
            { required: true, trigger: 'blur', message: '请选择仓库所在区' },
          ],
          code: [
            { required: true, trigger: 'blur', message: '请输入包裹单号' },
          ],
          // uid: [
          //   { required: true, trigger: 'change', message: '请选择所属用户' },
          // ],
        },
        title: '订单编辑',
      }
    },
    methods: {
      // 用户筛选
      remoteMethod(e) {
        this.page = 1
        this.limit = 20
        var data = {
          nickname: e,
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({ data }) => {
          this.options = data
        })
      },
      // 输入校验
      handleInputPrice() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.weight = this.form.weight
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      // 上传图片
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      async showEdit(row) {
        this.loading = true
        this.form.type = row.type
        orderDetail({ id: row.id }).then(({ data }) => {
          this.value1 = data.status
          this.form = data
          if (data.uid == 0) {
            this.form.uid = ''
          }

          this.getUserInfo({})

          if (this.form.type == 0) {
            this.form.type = null
          }
        })

        this.dialogFormVisible = true
      },

      async getUserInfo(e) {
        var data = {
          ...e,
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({ data }) => {
          // if (this.form.userInfo) {
          //   var a = JSON.stringify(data).indexOf(this.form.userInfo.code)
          //   console.log(data , '返回信息')
          //   if (a < 0) {
          //     data.push(this.form.userInfo)
          //   }
          // }

          this.options = data
          this.loading = false
        })
      },

      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        for (var key in this.form) {
          this.form[key] = null
        }
        this.value1 = null
        this.dialogFormVisible = false
      },

      // 提交修改//修改传ID  添加不传ID
      save() {
        this.form.status = this.value1
        var setDate = {
          id: this.form.id, //修改传 添加不传
          uid: this.form.uid,
          type: this.form.type,
          status: this.form.status,
          pic: this.form.pic,
          code: this.form.code,
          express_code: this.form.express_code,
          weight: this.form.weight,
        }
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { data, msg } = await orderUpdate(setDate)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
